/* istanbul ignore file */
// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {ComWixEcommerceValidationsSpiV1TargetNameInLineItem, ViolationLineItemTargetFragment} from '../../gql/graphql';

export class ViolationLineItemTargetModel {
  public name?: ViolationLineItemTargetName;
  public id?: string;
  constructor(lineItemTarget?: ViolationLineItemTargetFragment) {
    this.name = lineItemTarget?.name ? toViolationLineItemTargetName(lineItemTarget?.name) : undefined;
    this.id = lineItemTarget?.id ?? undefined;
  }
}

export enum ViolationLineItemTargetName {
  lineItemDefault = 'LINE_ITEM_DEFAULT',
  quantity = 'QUANTITY',
}

const toViolationLineItemTargetName = (otherTargetName: ComWixEcommerceValidationsSpiV1TargetNameInLineItem) => {
  switch (otherTargetName) {
    case ComWixEcommerceValidationsSpiV1TargetNameInLineItem.LINE_ITEM_DEFAULT:
      return ViolationLineItemTargetName.lineItemDefault;
    case ComWixEcommerceValidationsSpiV1TargetNameInLineItem.QUANTITY:
      return ViolationLineItemTargetName.quantity;
  }
};
