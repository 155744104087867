/* istanbul ignore file */
import {ComWixEcommerceValidationsSpiV1ViolationSeverity, ViolationFragment} from '../../gql/graphql';
import {ViolationTargetModel} from './ViolationTarget.model';

export enum ViolationSeverity {
  error = 'ERROR',
  warning = 'WARNING',
}

export class ViolationModel {
  public severity: ViolationSeverity;
  public description: string;
  public target?: ViolationTargetModel;
  constructor(violation?: ViolationFragment) {
    this.severity =
      violation?.severity === ComWixEcommerceValidationsSpiV1ViolationSeverity.ERROR
        ? ViolationSeverity.error
        : ViolationSeverity.warning;
    this.description = violation?.description ?? '';
    this.target = violation?.target ? new ViolationTargetModel(violation.target) : undefined;
  }
}
