import {StepsManagerService} from '../services/StepsManagerService';
import {BIService} from '../services/BIService';
import {CheckoutService} from '../services/CheckoutService';
import {CheckoutStep, StepId, StepsManagerStoreProps, StepState} from '../../types/app.types';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {MemberService} from '../services/MemberService';
import {FormsService} from '../services/FormsService';
import {isShippingFlow} from '../utils/checkoutFlow.utils';
import {NavigationService} from '../services/NavigationService';

export class StepsManagerStore {
  private readonly stepsManagerService: StepsManagerService;
  private readonly biService: BIService;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly memberService: MemberService;
  private readonly navigationService: NavigationService;
  private readonly formsService: FormsService;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;

  constructor({
    stepsManagerService,
    biService,
    checkoutService,
    checkoutSettingsService,
    memberService,
    updateComponent,
    formsService,
    navigationService,
    isSSR,
  }: {
    stepsManagerService: StepsManagerService;
    biService: BIService;
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    memberService: MemberService;
    updateComponent: () => void;
    formsService: FormsService;
    navigationService: NavigationService;
    isSSR: boolean;
  }) {
    this.stepsManagerService = stepsManagerService;
    this.biService = biService;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.memberService = memberService;
    this.updateComponent = updateComponent;
    this.formsService = formsService;
    this.navigationService = navigationService;
    this.isSSR = isSSR;
  }

  private readonly sendEditStepClickedBIEvent = (stepId: StepId): void => {
    const previousStepName = this.stepsManagerService.getPreviousStep();
    this.biService.sendEditStepClicked(this.checkoutService.checkout, stepId, previousStepName);
  };

  public initStepsManagerService = (): void => {
    this.stepsManagerService.stepsList = this.stepsManagerService.getSteps(this.checkoutService.checkout);
    void this.initializeCurrentStep();
  };

  private readonly initializeCurrentStep = async (): Promise<void> => {
    const initialStep = await this.calculateInitialStep();
    this.updateStepOnStage(initialStep, this.stepsManagerService.stepsList[initialStep]);
  };

  private readonly calculateInitialStep = async (): Promise<number> => {
    let initialStep = 0;
    if (!this.memberService.isMember() || !this.checkoutService.checkout.shippingDestination) {
      return initialStep;
    }

    // is empty and default is valid

    const shippingAddressIsValid = await this.formsService.isAddressValidForShipping(
      this.checkoutService.checkout.shippingDestination,
      {
        checkoutSettings: this.checkoutSettingsService.checkoutSettings,
        customField: this.checkoutService.checkout.customField,
        isShippingFlow: isShippingFlow({
          navigationService: this.navigationService,
          checkoutService: this.checkoutService,
        }),
      }
    );

    if (!shippingAddressIsValid) {
      return initialStep;
    }

    initialStep++;

    if (this.stepsManagerService.stepsList[initialStep] !== StepId.deliveryMethod) {
      return initialStep;
    }

    const isDeliveryMethodValid = !!this.checkoutService.checkout.selectedShippingOption;

    if (!isDeliveryMethodValid) {
      return initialStep;
    }

    initialStep++;

    return initialStep;
  };

  private readonly openStep = (stepIndex: number): void => {
    const stepId = this.stepsManagerService.stepsList[stepIndex];
    this.updateStepOnStage(stepIndex, stepId);
    this.sendEditStepClickedBIEvent(stepId);
  };

  private readonly updateStepOnStage = (stepIndex: number, stepId: StepId): void => {
    this.stepsManagerService.updateStepOnStage(stepIndex, stepId, {checkout: this.checkoutService.checkout});
    this.updateComponent();
  };

  private readonly shouldDisplayExpressCheckout = (): boolean => {
    return (
      !this.isSSR &&
      !this.checkoutService.checkout.hasSubscriptionItems &&
      !this.hasPayLater() &&
      this.stepsManagerService.getActiveStep().stepIndex === 0
    );
  };

  private readonly hasPayLater = () => {
    return Boolean(this.checkoutService.checkout.payLater?.total?.amount);
  };

  private readonly getCheckoutSteps = (): CheckoutStep[] => {
    const activeStepIndex = this.stepsManagerService.getActiveStep().stepIndex;
    const steps = this.stepsManagerService.stepsList;
    return steps.map((step, index) => {
      let state;
      const isLastStep = index === steps.length - 1;
      if (activeStepIndex < index) {
        state = StepState.EMPTY;
      } else {
        state = activeStepIndex === index || isLastStep ? StepState.OPEN : StepState.COLLAPSED;
      }

      return {
        id: step,
        state,
      };
    });
  };

  private readonly goToNewlyAddedStep = (
    previousSteps: StepId[],
    newSteps: StepId[],
    newIndexOfActiveStep: number
  ): void => {
    const activeStep = this.stepsManagerService.getActiveStep();
    let newStepIndex = newIndexOfActiveStep;
    let newStepId = activeStep.stepId;
    let i = 0;

    while (i < previousSteps.length) {
      if (previousSteps[i] !== newSteps[i]) {
        newStepIndex = i;
        newStepId = newSteps[i];
        break;
      }

      i++;
    }

    this.updateStepOnStage(newStepIndex, newStepId);
  };

  public toProps(): StepsManagerStoreProps {
    const previousSteps = [...this.stepsManagerService.stepsList];
    const newSteps = this.stepsManagerService.getSteps(this.checkoutService.checkout);
    this.stepsManagerService.stepsList = newSteps;
    const activeStep = this.stepsManagerService.getActiveStep();
    const newIndexOfActiveStep = newSteps.indexOf(activeStep.stepId);

    if (newIndexOfActiveStep === -1) {
      this.updateStepOnStage(activeStep.stepIndex, newSteps[activeStep.stepIndex]);
    } else if (newIndexOfActiveStep < activeStep.stepIndex) {
      this.updateStepOnStage(newIndexOfActiveStep, activeStep.stepId);
    } else if (newIndexOfActiveStep > activeStep.stepIndex) {
      this.goToNewlyAddedStep(previousSteps, newSteps, newIndexOfActiveStep);
    }

    return {
      updateStepOnStage: this.updateStepOnStage,
      openStep: this.openStep,
      sendEditStepClickedBIEvent: this.sendEditStepClickedBIEvent,
      shouldDisplayExpressCheckout: this.shouldDisplayExpressCheckout(),
      activeStep: this.stepsManagerService.getActiveStep(),
      stepsList: this.getCheckoutSteps(),
    };
  }
}
