import {Payments, StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {Checkout, LineItemWithEnrichment} from '../../types/app.types';
import {CheckoutWithEnrichedItemsFragment, LineItemFragment} from '../../gql/graphql';

export interface StoreCheckoutSettingsAndPayments {
  checkoutSettings: StoreCheckoutSettings;
  payments: Payments;
}

export function isCheckoutWithEnrichedItems(checkout: Checkout): checkout is CheckoutWithEnrichedItemsFragment {
  return (checkout as CheckoutWithEnrichedItemsFragment).externalEnrichedLineItems !== undefined;
}

export function isCheckoutWithViolations(checkout: Checkout): checkout is CheckoutWithEnrichedItemsFragment {
  return (checkout as CheckoutWithEnrichedItemsFragment).violations !== undefined;
}

export function isLineItemWithEnrichment(
  checkout: LineItemFragment | LineItemWithEnrichment
): checkout is LineItemWithEnrichment {
  return (checkout as LineItemWithEnrichment).renderingConfig !== undefined;
}
